/*
Stores component documentation for the components and other items in the ComponentEditor.

TODO: Link this up with the docs that in the top level folder of the project.

 */
let bar = {
    'name': 'bar',
    'description': 'Creates a bar plot',
    'long_description': `### 2.1 Mandatory Props
Mandatory Schema Attributes which are required for the component to function.
* \`type\` - \`bar\` - Component identifier.
* \`id\` - string - Unique component identifier. Must be unique to the current page.
* \`data-sources\` - array - An array of cluster data field names to be displayed.
* \`data-labels\` - array - An array of the labels to show for each field in \`data-sources\` (must be same length).

### 2.2 Options
* \`data-type\` - string - The type of data the graph will display, affects value formatting in tooltips (e.g. adding %
  signs). Valid options are: \`percentage\` or \`currency\`
* \`chart-options\` - object - ChartJS options to pass directly to the Bar Chart's Options Object. Any attributes added to 
this object will be transposed into the ChartJS \`options\` for the Bar Chart (overriding chart defaults if they overlap). 
See the ChartJS documentation for details on these options.`
}

let flatscatter = {
    'name': 'flatscatter',
    'description': 'Creates a "flat" scatter plot',
    'long_description': `# Flatscatter Chart Component

## 1. Purpose
Provides a 1d Scatter plot to display simple data sets. Either displays the provided fields for the current cluster or 
when set to \`cluster-compare\` mode, displays values for all clusters, highlighting the selected 
cluster. It's recommended to only use one value when in cluster compare mode, for readability reasons.

## 2. Component Reference

### 2.1 Mandatory Props
Mandatory Schema Attributes which are required for the component to function.
* \`type\` - \`flatscatter\` - Component identifier.
* \`id\` - string - Unique component identifier. Must be unique to the current page.
* \`data-sources\` - array - An array of cluster data field names to be displayed.
* \`data-labels\` - array - An array of the labels to show for each field in \`data-sources\` (must be same length).

### 2.2 Options
* \`mode\` - string - Changes the display mode of the chart. By default all data fields will be displayed for the current
cluster. If set to \`cluster-compare\` the graph will display the values for all clusters, with the current cluster being 
vertically offset (higher) on the X axis.
* \`data-type\` - string - The type of data the graph will display, affects value formatting in tooltips (e.g. adding %
  signs). Valid options are: \`percentage\` or \`currency\`
* \`chart-options\` - object - ChartJS options to pass directly to the Bar Chart's Options Object. Any attributes added to
  this object will be transposed into the ChartJS \`options\` for the Bar Chart (overriding chart defaults if they overlap).
  See the ChartJS documentation for details on these options.

#### Optional Schema Attributes, included in the \`options\` attribute under the main component object.

`
}

let ranking_table = {
    'name': 'ranking-table',
    'description': 'Creates a ranked table of data',
    'long_description':`
## 2. Component Reference
### 2.1 Mandatory Props
Mandatory Schema Attributes which are required for the component to function.
* \`type\` - \`ranking-table\` - Component identifier.
* \`id\` - string - Unique component identifier. Must be unique to the current page.
* \`data-sources\` - array - An array of cluster data field names to be displayed.
* \`n_rankings\` - int - Number of rows to display in table.

### 2.2 Options
#### Optional Schema Attributes, included in the \`options\` attribute under the main component object.
* \`table-label\` - string - Sets the header for the Data Property names column, defaults to 'Occupation'

## 3. Example


    \`\`\`json
'data-sources': [
    0: "P_Min_Tot"
    1: "P_Manf_Tot"
    2: "P_Elec_gas_wtr_wst_sv_Tot"
    3: "P_Con_Tot"
    4: "P_Wales_Tot"
    5: "P_Re_trd_Tot"
    6: "P_Acc_food_svc_Tot"
    7: "P_Trp_pos_wh_Tot"
    8: "P_In_med_tcom_Tot"
    9: "P_Fin_in_svc_Tot"
    10: "REst_To"
    11: "P_Prof_sc_tech_serv_Tot"
    12: "P_Adm_sup_serv_Tot"
    13: "P_PubAd_saf_Tot"
    14: "P_Ed_trng_Tot"
    15: "P_Hlth_Tot"
    16: "P_Arts_rec_svc_Tot"
    17: "P_Oth_svc_Tot"
    ],
'n_rankings': 5
'type': "ranking-table"
'options': {
  'table-label': 'Wibble'
}
\`\`\`
`
}
let table = {
    'name': 'ranking-table',
    'description': 'Creates a table of data',
    'long_description':`
## 2. Component Reference
### 2.1 Mandatory Props
Mandatory Schema Attributes which are required for the component to function.

A list of rows, each row having being an array of values with the following keys:
- \`header\` - string - The header for the row
- \`description\` - string - The description for the row
- \`meta-key\` or \`data-key\` - string - The key for the data to be displayed in the row. \`meta-key\` is used for
    metadata about the dataset (ref dataset's metadata field), \`data-key\` is used for data from the current cluster.


### 2.2 Options
#### Optional Schema Attributes, included in the \`options\` attribute under the main component object.
* \`table-label\` - string - Sets the header for the Data Property names column, defaults to 'Occupation'

## 3. Example


    \`\`\`json
'data-sources': [
    0: "P_Min_Tot"
    1: "P_Manf_Tot"
    2: "P_Elec_gas_wtr_wst_sv_Tot"
    3: "P_Con_Tot"
    4: "P_Wales_Tot"
    5: "P_Re_trd_Tot"
    6: "P_Acc_food_svc_Tot"
    7: "P_Trp_pos_wh_Tot"
    8: "P_In_med_tcom_Tot"
    9: "P_Fin_in_svc_Tot"
    10: "REst_To"
    11: "P_Prof_sc_tech_serv_Tot"
    12: "P_Adm_sup_serv_Tot"
    13: "P_PubAd_saf_Tot"
    14: "P_Ed_trng_Tot"
    15: "P_Hlth_Tot"
    16: "P_Arts_rec_svc_Tot"
    17: "P_Oth_svc_Tot"
    ],
'n_rankings': 5
'type': "ranking-table"
'options': {
  'table-label': 'Wibble'
}
\`\`\`
`
}

export default {
    bar,
    flatscatter,
    ranking_table,
    table
}