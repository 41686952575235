<template>
  <div class="component-editor-parent">
    <b-form-group label="Component Type">
      <b-form-select v-model="value['type']" @change="updateValue($event)">
        <b-form-select-option
            v-for="component_type in Object.keys(valid_component_types)"
            :key="component_type"
            :value="component_type"
        >{{ component_type }}
        </b-form-select-option
        >
      </b-form-select>
      <!-- alert the user if the component type is not valid -->
      <b-alert
          v-if="!Object.keys(valid_component_types).includes(value['type'])"
          show
          variant="danger"
      >Invalid component type: {{ value['type'] }}
      </b-alert>
    </b-form-group>
    <div v-if="Object.keys(valid_component_types).includes(value['type'])">
      <b-button v-b-toggle="'component-help-' + value['type']" variant="info">Help</b-button>
      <b-collapse :id="'component-help-' + value['type']" class="row" v-if="valid_component_types[value['type']].docs">
        <b>{{ valid_component_types[value['type']].docs.name }}</b>:
        {{ valid_component_types[value['type']].docs.description }}
        <br/>
        <VueShowdown :markdown="valid_component_types[value['type']].docs.long_description"/>
      </b-collapse>
      <div class="row"
           v-for="(arg, arg_name) in valid_component_types[value['type']]['args']" v-bind:key="arg_name">
        <b-form-group :label="arg_name" class="basic-border">
          <!-- if the type's first token, splitting by '-' is list, use the list editor -->
          <div v-if="arg.split('-')[0] === 'list'">
            <ListEditor
                v-model="value[arg_name]"></ListEditor>
          </div>
          <div v-else-if="arg.split('-')[0] === 'int'">
            <b-input type="number" v-model="value[arg_name]"></b-input>
          </div>
          <div v-else-if="arg === 'feature-multiselect'">
            <DatasetFeatureMultiSelect
                v-model="value[arg_name]"
                :valid_features="valid_dataset_features"
                :multiple="true"
                show_features
            ></DatasetFeatureMultiSelect>
          </div>
          <div v-else-if="arg.split('-')[0] === 'string'">
            <b-textarea v-model="value[arg_name]"></b-textarea>
          </div>
          <div v-else-if="arg.split('-')[0] === 'json'">
            <b-textarea :value="JSON.stringify(value[arg_name])"
                        v-on:change="updateValue(arg_name, $event)"></b-textarea>
          </div>
          <div v-else-if="arg === 'data-type-select'">
            <b-select v-model="value[arg_name]">
              <b-form-select-option
                  v-for="data_type in valid_data_types"
                  :key="data_type"
                  :value="data_type"
              >{{ data_type }}
              </b-form-select-option>
            </b-select>
          </div>
          <div v-else>
            Unknown???: {{ arg }} - {{ arg.split('-')[0] }} - {{ arg_name }} - {{ value }}
            <b-textarea :value="JSON.stringify(value[arg_name])"
                        v-on:change="updateValue(arg_name, $event)"></b-textarea>
          </div>
        </b-form-group>
      </div>
      <!-- advanced options show/hid -->
      <b-button v-b-toggle.advanced-options>Advanced Component Options</b-button>
      <b-collapse id="advanced-options">

        <b-form-group label="Component ID">
          <b-input :value="JSON.stringify(value['id'])"
                   v-on:change="updateId($event)"></b-input>
          <b-button variant="info" v-on:click="setId('id-' + Math.random().toString(36).substring(2, 5))">Generate
            Random ID
          </b-button>
        </b-form-group>
        <b-form-group label="Options">
          <b-textarea :value="JSON.stringify(value['options'])"
                      v-on:change="updateOptions($event)"></b-textarea>
        </b-form-group>
        <!-- button to delete component -->
        <b-button variant="danger" v-on:click="deleteComponent">Delete Component</b-button>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import ListEditor from "@/components/report_editing/ListEditor.vue";
import DatasetFeatureMultiSelect from "@/components/report_editing/DatasetFeatureMultiSelect.vue";
import Vue from "vue";
import component_documentation from "@/component_documentation";

export default {
  name: "DashboardComponentEditor",
  components: {DatasetFeatureMultiSelect, ListEditor},
  props: {
    idx: Number,
    value: Object,
    valid_dataset_features: Array,
  },
  data: function () {
    return {
      valid_component_types: {
        'bar': {
          'args': {
            'data-labels': 'list-str',
            'data-sources': 'feature-multiselect',
            'data-type': 'data-type-select',
          },
          'docs': component_documentation['bar']
        },
        'markdown': {
          'args': {
            'body': 'string-markdown',
          }
        },
        'flatscatter': {
          'args': {
            'data-icon': 'list-str',
            'data-labels': 'list-str',
            'data-sources': 'feature-multiselect',
            'data-type': 'data-type-select',
            'mode': 'string'
          },
          'docs': component_documentation['flatscatter']
        },
        'report-params': {
          'args': {
            'rows': 'json', // TODO: better.  It is really a "container" for other components
          }
        },
        'table': {
          'args': {
            'rows': 'json', // TODO make a custom component for this, or ammend ListEditor
          },
          'docs': component_documentation['table']
        },
        'radar': {
          'args': {
            'data-labels': 'list-str',
            'data-sources': 'feature-multiselect',
            'options': 'json'
          }
        },
        'ranking-table': {
          'args': {
            'data-labels': 'list-str',
            'data-sources': 'feature-multiselect',
            'n_rankings': 'int-positive',
            'options': 'json'
          },
          'docs': component_documentation['ranking_table']
        },
        'highlights': {
          'args': {
            'data-labels': 'list-str',
            'data-sources': 'feature-multiselect',
            'formatters': 'list-str',
            'options': 'json'
          }
        }
      },
      valid_data_types: [
        'none',
        'percentage',
        'currency',
        'list'
      ]
    }
  },
  methods: {
    setId: function (id) {
      Vue.set(this.value, 'id', id)
      this.$emit('input', this.value)
    },
    updateId: function (event) {
      Vue.set(this.value, 'id', JSON.parse(event))
      this.$emit('input', this.value)
    },
    updateOptions: function (event) {
      Vue.set(this.value, 'options', JSON.parse(event))
      this.$emit('input', this.value)
    },
    updateValue: function (arg_name, event) {

      // for the given value type, if any of its arguments are not set, set them with a default value
      console.log(this.value['type'])
      console.log(Object.keys(this.valid_component_types).includes(this.value['type']))
      if (Object.keys(this.valid_component_types).includes(this.value['type'])) {
        let component_type = this.valid_component_types[this.value['type']]
        for (let argument in component_type['args']) {
          console.log(argument)
          if (!Object.keys(this.value).includes(argument)) {
            if (component_type['args'][argument] === 'list-str') {
              Vue.set(this.value, argument, [])
            } else if (component_type['args'][argument] === 'feature-multiselect') {
              Vue.set(this.value, argument, [])
            } else if (component_type['args'][argument] === 'string-markdown') {
              Vue.set(this.value, argument, '')
            } else if (component_type['args'][argument] === 'data-type-select') {
              Vue.set(this.value, argument, 'none')
            } else if (component_type['args'][argument] === 'json') {
              Vue.set(this.value, argument, {})
            } else if (component_type['args'][argument] === 'int-positive') {
              Vue.set(this.value, argument, 1)
            } else {
              Vue.set(this.value, argument, '')
            }
          }
        }

      }
      Vue.set(this.value, arg_name, JSON.parse(event))
      this.$emit('input', this.value)
    },
    deleteComponent: function () {
      this.$emit('deleteComponent', this.idx)
    }
  }
}
</script>

<style scoped>
.basic-border {
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  width: 90%;
}

.component-editor-parent {
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  margin-bottom: 1rem;
}

</style>