<template>
  <div>
    <b-form-group label="Navigation">
      <b-textarea :value="JSON.stringify(value['navigation'])"
                  v-on:change="updateNavigation($event)"></b-textarea>
    </b-form-group>
    <b-form-group label="Options">
      <b-textarea :value="JSON.stringify(value['options'])"
                  v-on:change="updateOptions($event)"></b-textarea>
    </b-form-group>

    <br>

    <b-card v-for="(component, idx) in page_layouts_by_index" v-bind:key="idx">
      <h3>Grouping: {{ value['content'][idx]['title'] }}</h3>


      <b-form-group label="Title">
        <b-textarea v-model="value['content'][idx]['title']"
        ></b-textarea>
      </b-form-group>
      <b-form-group label="Columns (out of 12)">
        <b-input type="number" v-model="value['content'][idx]['col-size']"></b-input>
      </b-form-group>
      <DashboardComponentListEditor
          v-model="value['content'][idx].components"
          :valid_dataset_features="valid_dataset_features"
      ></DashboardComponentListEditor>    <!-- button for showing/hiding advanced options, and the advanced options themselves -->
      <b-button variant="primary"
                v-on:click="show_advanced_options = !show_advanced_options">Advanced Grouping Options
      </b-button>
      <b-collapse v-model="show_advanced_options">
        <br>
        <!-- Button to Move the grouping up, but only if the index is not 0 -->
        <b-button variant="primary"
                  :disabled="parseInt(idx) === 0"
                  v-on:click="moveGroupingUp(idx)">Up
        </b-button>

        <b-button v-on:click="removeGrouping(idx)">Remove Grouping</b-button>
        <!-- Button to Move the grouping down, but only if the index is not the last one -->
        <b-button variant="success"
                  :disabled="parseInt(idx) === Object.keys(page_layouts_by_index).length - 1"
                  v-on:click="moveGroupingDown(idx)">Down
        </b-button>
        <b-form-group label="Grouping ID">
          <b-input v-model="value['content'][idx]['id']"></b-input>
        </b-form-group>
        <b-form-group label="Options">
          <b-textarea :value="JSON.stringify(value['content'][idx].options)"
                      v-on:change="updateValue(idx, $event)"
          ></b-textarea>
        </b-form-group>
      </b-collapse>
    </b-card>
    <b-button variant="success"
              v-on:click="appendGrouping()">Add Grouping
    </b-button>



  </div>
</template>

<script>
import DashboardComponentListEditor from "@/components/report_editing/DashboardComponentListEditor.vue";
import Vue from "vue";

export default {
  name: "PageEditor",
  components: {DashboardComponentListEditor},
  props: {
    value: Object,
    valid_dataset_features: Array
  },
  data: function () {
    return {show_advanced_options: false}
  },
  computed: {
    page_layouts_by_index: function () {
      let components_by_index = {}
      this.value.content.forEach((component, index) => {
        components_by_index[index] = component;
      });
      return components_by_index;
    }
  },
  methods: {
    removeGrouping: function (idx) {
      this.$delete(this.value.content, idx)
      this.$emit('input', this.value)
    },
    moveGroupingUp: function (idx) {
      if (idx === 0) {
        return
      }
      idx = parseInt(idx)
      let temp = this.value.content[idx - 1]
      this.$set(this.value.content, idx - 1, this.value.content[idx])
      this.$set(this.value.content, idx, temp)
      this.$emit('input', this.value)
    },
    moveGroupingDown: function (idx) {
      if (idx >= Object.keys(this.value.content).length - 1) {
        return
      }
      idx = parseInt(idx)
      let temp = this.value.content[idx + 1]
      this.$set(this.value.content, idx + 1, this.value.content[idx])
      this.$set(this.value.content, idx, temp)
      this.$emit('input', this.value)
    },
    appendGrouping: function () {
      this.$set(this.value.content, this.value.content.length, {
        id: '',
        title: '',
        'col-size': 12,
        components: [],
        options: {}
      })
      this.$emit('input', this.value)
    },
    updateTitle: function (idx, value) {
      this.$set(this.value.content[idx], 'title', value);
      this.$emit('input', this.value)
    },
    updateValue: function (idx, value) {
      this.$set(this.value.content[idx], 'options', JSON.parse(value));
      this.$emit('input', this.value)
    },
    updateNavigation: function (event) {
      Vue.set(this.value, 'navigation', JSON.parse(event))
      this.$emit('input', this.value)
    },
    updateOptions: function (event) {
      Vue.set(this.value, 'options', JSON.parse(event))
      this.$emit('input', this.value)
    }
  }
}
</script>

<style scoped>

</style>