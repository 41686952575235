<template>
  <div>
    <div v-for="(component, idx) in values_by_index" v-bind:key="idx">
      <div class="row">
        <b-input-group :prepend="idx" class="col">
          <b-input :value="values_by_index[idx]"
                   v-on:change="updateValue(idx, $event)"
          ></b-input>
        </b-input-group>
        <div class="col">
          <b-button variant="primary"
                    :disabled="parseInt(idx) === 0"
                    v-on:click="moveUp(idx)">Up
          </b-button>
          <b-button variant="success"
                    :disabled="parseInt(idx) === Object.keys(values_by_index).length - 1"
                    v-on:click="moveDown(idx)">Down
          </b-button>
          <b-button variant="danger"
                    v-on:click="deleteItem(idx)">Delete
          </b-button>
        </div>
      </div>
    </div>
    <b-button variant="success"
              v-on:click="append()">Add
    </b-button>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "ListEditor",
  props: {
    value: Array,
    datatype: String,
  },
  data: function () {
    return {
      values_by_index: {}
    }
  },
  methods: {
    updateValue: function (idx, value) {
      console.log(idx, value);
      if (this.datatype === 'number') {
        value = Number(value);
      }
      Vue.set(this.values_by_index, idx, value);
      let sorted_values = Object.keys(this.values_by_index).sort().map(key => this.values_by_index[key]);
      this.$emit('input', sorted_values);
      this.refreshValuesByIndex()
    },
    refreshValuesByIndex: function () {
      if (!this.value) {
        return
      }
      this.values_by_index = {}
      this.value.forEach((cur_value, index) => {
        this.values_by_index[index] = cur_value;
      });
    },
    moveUp(idx) {
      let previous = parseInt(idx) - 1;
      this.swap(idx, previous);
    },
    moveDown(idx) {
      let next = parseInt(idx) + 1;
      this.swap(idx, next);
    },
    deleteItem(idx) {
      this.value.splice(idx, 1);
      this.refreshValuesByIndex()
    },
    append() {
      if (!this.value) {
        this.value = []
      }
      this.value.push('?');
      this.refreshValuesByIndex()
    },
    swap(idx1, idx2) {
      console.log(idx1, idx2)
      let temp1 = this.value[idx1];
      let temp2 = this.value[idx2];
      console.log(temp1, temp2)
      Vue.set(this.value, idx1, temp2);
      Vue.set(this.value, idx2, temp1);
      this.refreshValuesByIndex()
    },
  },
  mounted() {
    this.refreshValuesByIndex()
  }
}
</script>

<style scoped>

.no-pad {
  display: flex;
  align-items: start;
}
</style>