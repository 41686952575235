<template>
  <div>
    <h5>Components</h5>
    <div v-for="(component, idx) in components_by_index" v-bind:key="idx">
      <!-- when the title is clicked, toggle the collapse -->
      <h6>
        <span v-b-toggle="value[idx]['id'] + idx">
          Component {{ value[idx]['id'] + idx }}
        </span>
        <b-button variant="success btn-sm" v-on:click="move(idx, 'up')" v-if="idx > 0">Up</b-button>
        <b-button variant="primary btn-sm" v-on:click="move(idx, 'down')" v-if="idx < value.length - 1">Down</b-button>
        <b-button variant="danger btn-sm" v-on:click="deleteComponent(idx)">Delete</b-button>
      </h6>
      <div class="offset-left">
        <b-collapse :id="value[idx]['id'] + idx" accordion="component-editor-accordian">
          <DashboardComponentEditor v-model="value[idx]"
                                    :idx="parseInt(idx)"
                                    :valid_dataset_features="valid_dataset_features"
                                    @deleteComponent="deleteComponent"
                                    @move="move"
          ></DashboardComponentEditor>
        </b-collapse>
      </div>
    </div>
    <b-form inline>
      <b-button @click="$emit('input', value.concat(newComponent()))" variant="success">Add Component</b-button>
    </b-form>
  </div>
</template>

<script>
import DashboardComponentEditor from "@/components/report_editing/DashboardComponentEditor.vue";

export default {
  name: "DashboardComponentListEditor",
  components: {DashboardComponentEditor},
  props: {
    value: Array,
    valid_dataset_features: Array
  },
  computed: {
    components_by_index: function () {
      let components_by_index = {}
      this.value.forEach((component, index) => {
        components_by_index[index] = component;
      });
      return components_by_index;
    }
  },
  methods: {
    newComponent: function () {
      return {
        // Generate random ID of four random characters after "id-" prefix
        id: 'id-' + Math.random().toString(36).substring(2, 5),
        options: {},
        data: {
          type: 'dataset',
          dataset: 'new-dataset',
          features: [],
        },
      }
    },
    toggleComponent: function (idx) {
      this.$root.$emit('bv::toggle::collapse', idx)
    },
    move: function (idx, direction) {
      if (direction === 'up') {
        this.moveComponentUp(idx)
      } else if (direction === 'down') {
        this.moveComponentDown(idx)
      }
    },
    moveComponentUp: function (idx) {
      if (idx > 0) {
        let new_idx = idx - 1
        let new_value = this.value.slice()
        new_value.splice(new_idx, 0, new_value.splice(idx, 1)[0])
        this.$emit('input', new_value)
      }
    },
    moveComponentDown: function (idx) {
      if (idx < this.value.length - 1) {
        let new_idx = idx + 1
        let new_value = this.value.slice()
        new_value.splice(new_idx, 0, new_value.splice(idx, 1)[0])
        this.$emit('input', new_value)
      }
    },
    deleteComponent: function (idx) {
      this.$delete(this.value, idx)
      this.$emit('input', this.value)
    },
  }
}
</script>

<style scoped>
.offset-left {
  margin-left: 10px;
  border: 3px solid cornflowerblue;
  padding-left: 10px;
}
</style>