<template>
  <b-form-group label="Features" description="Which features do you want to combine?">
    Filter:
    <b-input v-model="feature_filter"></b-input>
    <br>
    <b-form-select
        v-on:change="featureAdded"
        :options="filteredFeatures"
    ></b-form-select>

    <div class="feature-list" v-if="show_features">
      <!-- List all features as bullets -->
      <b-list-group>
        <b-list-group-item
            v-for="feature in selected_features"
            v-bind:key="feature"
        >
          {{ feature }}
          <b-button v-on:click="removeFeature(feature)">Remove</b-button>
        </b-list-group-item>
      </b-list-group>
    </div>
    There are {{ valid_features.length }} features available.
  </b-form-group>
</template>

<script>
export default {
  name: "DatasetFeatureMultiSelect",
  props: {
    valid_features: Array,
    value: Array,
    multiple: Boolean,
    show_features: Boolean,
  },
  data() {
    return {
      feature_filter: '',
      selected_features: []
    }
  },
  watch: {
    selected_features() {
      this.$emit('input', this.selected_features)
    }
  },
  computed: {
    filteredFeatures() {
      let filtered_features = this.valid_features.filter(feature => {
        return feature.toLowerCase().includes(this.feature_filter.toLowerCase());
      });

      // Sort by alphabetical first, but any selected features on top
      filtered_features.sort((a, b) => {
        if (this.selected_features.includes(a) && !this.selected_features.includes(b)) {
          return -1;
        } else if (!this.selected_features.includes(a) && this.selected_features.includes(b)) {
          return 1;
        } else {
          return a.localeCompare(b);
        }
      });

      return filtered_features
    },
  },
  methods: {
    featureAdded(event) {
      this.selected_features.push(event);
      console.log(this.selected_features);
      this.$emit('datasets_changed', this.selected_features)
    },
    removeFeature(feature) {
      this.selected_features.splice(this.selected_features.indexOf(feature), 1);
      this.$emit('datasets_changed', this.selected_features)
    }
  },
  mounted() {
    if (this.value){
      this.selected_features = this.value;
    }else{
      this.selected_features = [];
    }
  }
}
</script>

<style scoped>

</style>