<template>
  <div class="results_parent" v-if="dashboard">
    <h1>Edit Dashboard :: {{ dashboard.name }}</h1>
    <b-button
        @click="update_dashboard()"
        variant="success"
        size="lg"
    >Save
    </b-button>
    <b-button
        @click="rerunDashboard()"
        variant="success"
        size="lg"
    >Rerun
    </b-button>
    <!-- Export the dashboard as a JSON file -->
    <b-button
        v-if="dashboard.id"
        @click="exportDashboard()"
        variant="primary"
        size="lg" class="float-right"
    >Export <i class="fas fa-file-export"></i>
    </b-button>
    <!-- Import a dashboard from a JSON file -->
    <b-button
        v-if="dashboard.id"
        @click="importDashboard()"
        variant="primary"
        size="lg" class="float-right"
    >Import <i class="fas fa-file-import"></i>
    </b-button>

    <!-- Link to the Dashboard page for this dashboard -->
    <b-button
        v-if="dashboard.id"
        v-bind:to="{name: 'dashboard_viewer', params: {dashboard_name: dashboard.id}}"
        variant="primary"
        size="lg" class="float-right"
    >View
    </b-button>
    <!-- Create a scrollable section that takes up the rest of the screen -->
    <div class="expand-width">
      <div id="schema_editor">
        <b-card>
          <h3 v-b-toggle.dashboard-metadata>
            <span class="when-open">V</span><span class="when-closed">></span>
            Dashboard Metadata
          </h3>
          <b-collapse id="dashboard-metadata">

            <div v-for="key in editable_keys" v-bind:key="key">
              <div v-if="key !== 'schema'" class="form-group row col-md-12">
                <div class="col">
                  {{ key }}
                </div>
                <div class="col">
                  <!-- input if its a number or text -->
                  <div v-if="typeof dashboard[key] === 'number' || typeof dashboard[key] === 'string'">
                    <b-form-input
                        v-model="dashboard[key]"
                        placeholder="Set Value"
                    ></b-form-input>
                  </div>
                  <div v-else>
                    {{ dashboard[key] }}
                  </div>
                </div>
              </div>
            </div>
            <h3>Job Options</h3>
            <h4 v-b-toggle.dashboard-job-categories>
              <span class="when-open">V</span><span class="when-closed">></span>
              Segmenting Datasets
            </h4>

            <b-collapse id="dashboard-job-categories">
              <b-form-group>
                <h4>By category</h4>
                <DatasetFeatureMultiSelect
                    v-model="dashboard.job.parameters.datasets_query.datasets"
                    :valid_features="valid_features"
                    @datasets_changed="dashboard.job.parameters.datasets_query.datasets = $event"
                    :multiple="true"
                    show_features
                ></DatasetFeatureMultiSelect>
              </b-form-group>
              <b-form-group>
                <h4>By category</h4>
                <DatasetCategoryMultiSelect
                    v-model="dashboard.job.parameters.datasets_query.categories"
                    @categories_changed="dashboard.job.parameters.datasets_query.categories = $event"
                    :categories="dataset_categories"
                    :multiple="true"
                    :select-size="10">
                </DatasetCategoryMultiSelect>
              </b-form-group>
            </b-collapse>
            <div v-for="key in job_editable_keys" v-bind:key="'c-' + key">
              <div class="form-group row col-md-12">
                <div class="col">
                  {{ key }}
                </div>
                <div class="col">
                  <!-- input if its a text -->
                  <div v-if="data_types[key] === 'string'">
                    <b-form-input
                        v-model="dashboard.job.parameters[key]"
                        placeholder="Set Value"
                    ></b-form-input>
                  </div>
                  <div v-else-if="data_types[key] === 'number'">
                    <b-form-input
                        v-model="dashboard.job.parameters[key]"
                        placeholder="Set Value"
                        type="number"
                    ></b-form-input>
                  </div>
                  <div v-else>
                    {{ dashboard.job.parameters[key] }}
                  </div>
                </div>
              </div>
            </div>
            <b-form-group>
              <h4>Sort Clusters by dataset</h4>
              <DatasetFeatureMultiSelect
                  v-model="dashboard.job.parameters.order_clusters_by"
                  :valid_features="valid_features"
                  @datasets_changed="dashboard.job.parameters.order_clusters_by = $event"
                  :multiple="true"
                  show_features
              ></DatasetFeatureMultiSelect>
            </b-form-group>
            <p>
              Note that updating these keys does not automatically rerun the job, even on save.
              To rerun, press this button:
            </p>
            <b-button
                @click="rerunDashboard()"
                variant="success"
                size="lg"
            >Rerun
            </b-button>
          </b-collapse>
        </b-card>

        <SchemaEditor
            :schema="dashboard.schema"
            :datasets="dataset_categories"
        ></SchemaEditor>


        <b-card id="json-output">
          <h3 v-b-toggle.live-state>
            <span class="when-open">V</span><span class="when-closed">></span>
            Live State
          </h3>
          <b-collapse id="live-state">
            <pre>{{ dashboard }}</pre>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardService from '../services/dashboard.service';

import vue2_ace_editor from "vue2-ace-editor";
import SchemaEditor from "@/views/editor/SchemaEditor.vue";
import DatasetCategoryMultiSelect from "@/components/report_editing/DatasetMultiSelect.vue";
import DatasetService from "@/services/dataset.service";
import DatasetFeatureMultiSelect from "@/components/report_editing/DatasetFeatureMultiSelect.vue";

export default {
  name: 'EditDashboard',
  components: {DatasetFeatureMultiSelect, DatasetCategoryMultiSelect, SchemaEditor},
  data() {
    return {
      dashboard: null,
      schema_string: '',
      features: [],

      // The dashboard the user wants to copy from
      selected_dashboard_to_copy: null,

      // Editable keys at the top level of dashboard
      editable_keys: ['name', 'boundary_name', 'applicable_date',
        'organisation'],
      job_editable_keys: ['k', 'boundary_name', 'report_name', 'start_date',],
      data_types: {
        'k': 'number',
        'boundary_name': 'string',  // TODO: make it "boundary" and have a dropdown
        'report_name': 'string',
        'start_date': 'string', // TODO: make it a date
      },
      // TODO: turn the datasets list into a call
      dataset_categories: ["work travel", "rent", "internet", "mortgage", "house profiles", "employment figures", "job types", "education locations", "education profile", "amenities", "income", "age profile", "population", "car ownership", "ethnicity (by parent's birth country)", "swc-loans", "lote", "health", "crime", "occupation", "households", "languages", "house_prices", "Victorian State Election"],
      dataset_datasets_chosen: [],
      dataset_categories_chosen: [],
    };
  },
  computed: {
    valid_features() {
      // Add all the metafeatures to the features and return the list
      let features = this.features;
      if (this.dashboard){
        for (let key in this.dashboard.schema.metafeatures) {
          features.push(key);
        }
      }
      return features;
    }
  },
  methods: {
    editorInit: function () {
    },
    exportDashboard() {
      // Download dashboard as JSON
      const dataStr =
          'data:text/json;charset=utf-8,' +
          encodeURIComponent(JSON.stringify(this.dashboard));
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href', dataStr);
      downloadAnchorNode.setAttribute('download', 'dashboard.json');
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },
    importDashboard() {
      // Import dashboard from JSON
      const input = document.createElement('input');
      input.type = 'file';
      // Ask user to select file
      input.onchange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsText(file, 'UTF-8');
        // Read the file
        reader.onload = readerEvent => {
          const content = readerEvent.target.result;
          // Parse the JSON
          const dashboard = JSON.parse(content);
          // Overwrite the ID with the current dashboards ID
          dashboard.id = this.dashboard.id;
          // Set the dashboard
          this.dashboard = dashboard;
          // Set the schema string
          this.set_schema_string();
        };
      };
      input.click();
    },
    rerunDashboard: async function () {
      try {
        let response = await DashboardService.rerunReport(this.dashboard.id)
        if (response.data.success) {
          this.$bvToast.toast('Report Rerun Successfully Started', {
            title: 'Report Refreshed',
            variant: 'success',
            toaster: 'b-toaster-top-center'
          })
        } else {
          this.$bvToast.toast('Error, Failed to Rerun Report. Please try again later.', {
            title: 'Rerun Report Error',
            variant: 'danger',
            toaster: 'b-toaster-top-center'
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    update_dashboard() {
      DashboardService.updateDashboard(this.dashboard.id, this.dashboard).then(
          (response) => {
            alert('Save complete');
          },
          (error) => {
            alert('Could not save schema');
          }
      );
    },
    copy_from_dashboard() {
      if (!this.selected_dashboard_to_copy) {
        console.error('No dashboard selected to copy');
        return;
      }
      console.log('Copying from dashboard:', this.selected_dashboard_to_copy);
      DashboardService.getDashboardSchema(
          this.$route.params.dashboard_id
      ).then(
          (response) => {
            this.dashboard.schema = response.data['dashboard'].schema;
            this.set_schema_string();
            alert(
                'Copied from dashboard to existing one. Click save to overwrite dashboard.'
            );
          },
          (error) => {
            this.content =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
          }
      );
    },
    format_json() {
      // Parse the JSON as is, and then set it again to format
      return this.set_schema_string(JSON.parse(this.schema_string));
    },
    reset_schema_from_dashboard() {
      // Set the schema as the original schema from the return dashboard
      return this.set_schema_string(this.dashboard.schema);
    },
    set_schema_string() {
      this.schema_string = JSON.stringify(this.dashboard.schema, null, 4);
    },
    async loadAllFeatures() {
      DatasetService.getAllDatasetFeatures().then(
          (response) => {
            // Only the name from all features in response.data['datasets']
            this.features = response.data['datasets'].map(x => x.name);
            console.log('Dataset Features:');
            console.log(this.features);
            this.loading = false
          },
          (error) => {
            this.content =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
            this.loading = false
          }
      );
    },
  },
  async mounted() {
    DashboardService.getDashboardSchema(this.$route.params.dashboard_id).then(
        (response) => {
          this.dashboard = response.data['dashboard'];
          // If the dashboard.job.parameters.datasets_query is set and a list
          if (this.dashboard.job.parameters.datasets_query &&
              Array.isArray(this.dashboard.job.parameters.datasets_query)) {
            console.log("Fixing datasets_query")
            // Set the chosen datasets to the list
            this.dashboard.job.parameters.datasets_query = { categories: this.dashboard.job.parameters.datasets_query}
          }
          this.reset_schema_from_dashboard();
          console.log('Dashboard:');
          console.log({...this.dashboard});
        },
        (error) => {
          this.content =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
        }
    );
    DashboardService.getAllDashboards().then(
        (response) => {
          this.dashboard_list = response.data['dashboards'];
          console.log('Other Dashboards:');
          console.log(this.dashboard_list);
        },
        (error) => {
          this.content =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
        }
    );
    await this.loadAllFeatures()
  },
};
</script>

<style scoped>
.btn {
  margin: 8pt;
}

.results_parent {
  width: 100%;
  min-width: 600pt;
  margin: auto;
  padding: 16pt;
  background-color: #e9ecef;
  /* height is maximum */
  height: 100%;
}

#schema_editor {
  font-size: 16pt;
  width: 99%;
  min-width: 500pt;
  max-width: 1000pt;
}

.pull_right {
  float: right;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.expand-width{
  width: 100%;
  overflow: scroll;
  height: 80vh;
}
</style>
