<template>
  <div class="schema-editor">
    <b-card>
      <h3 v-b-toggle.schema-metadata>
        <span class="when-open">V</span><span class="when-closed">></span>
        Schema Metadata
      </h3>
      <b-collapse id="schema-metadata" accordion="dashboard-editor-accordian">
        <div class="schema-editor-controls">
          <b-form inline>
            <b-form-group>
              <b-form-select
                  v-if="dashboard_list"
                  v-model="selected_dashboard_to_copy"
              >
                <b-form-select-option
                    v-for="dashboard in dashboard_list"
                    :key="dashboard.id"
                    :value="dashboard.id"
                >{{ dashboard.name }} -
                  {{ dashboard.boundary_name }}
                </b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </b-form>
        </div>

        <div class="schema-editor-main">

          <!-- text editor for editing the key comment -->
          <div class="schema-editor-item">
            <h4 v-b-toggle.comment>
              <span class="when-open">V</span><span class="when-closed">></span>
              Comment
            </h4>
            <b-collapse id="comment" accordion="schema-editor-accordian">
              <b-form-group>
                <b-form-textarea
                    v-model="schema.comment"
                    :rows="5"
                    :max-rows="10"
                    placeholder="Enter a comment for this schema"
                ></b-form-textarea>
              </b-form-group>
            </b-collapse>
          </div>
          <div class="schema-editor-item">
            <h4 v-b-toggle.categories>
              <span class="when-open">V</span><span class="when-closed">></span>
              Dataset Categories
            </h4>

            <b-collapse id="categories" accordion="schema-editor-accordian">
              <b-form-group>
                <DatasetCategoryMultiSelect
                    v-model="schema.datasets"
                    @categories_changed="schema.datasets = $event"
                    :categories="datasets"
                    :multiple="true"
                    :select-size="10">
                </DatasetCategoryMultiSelect>
              </b-form-group>
            </b-collapse>
          </div>

          <div class="schema-editor-item">
            <h4 v-b-toggle.metafeatures>
              <span class="when-open">V</span><span class="when-closed">></span>
              Metafeatures
            </h4>

            <b-collapse id="metafeatures" accordion="schema-editor-accordian">
              <p>
                A metafeature is a new feature composed by some operation on other features.
                Metafeatures can be composed of other metafeatures, as long as no circular reference is created.
                This is not checked for by this editor.
              </p>
              <b-card>
                <h5>Add</h5>
                <b-form-group label="Name" description="The name of the new metafeature">
                  <b-form-input v-model="new_metafeature_name"></b-form-input>
                </b-form-group>
                <b-button type="submit" variant="primary" @click="addMetafeature">Add</b-button>
              </b-card>
              <!-- loop over key, values in schema.metafeatures -->
              <b-card v-for="(value, key) in schema.metafeatures" v-bind:key="key">
                <h5>
                  {{ key }}
                  <b-button v-b-toggle="slug(key)" variant="outline-primary" size="sm">Show</b-button>
                  <b-button
                      @click="deleteMetafeature(key)"
                      variant="outline-danger"
                      size="sm"
                      class="pull-right"
                  >Delete
                  </b-button
                  >
                </h5>
                <b-collapse :id="slug(key)" accordion="metafeatures">
                  <b-form-group label="Aggregation Operation" description="How do you want us to combine the values?">
                    <b-select
                        v-model="schema.metafeatures[key].aggregation"
                        :options="valid_metadata_operations"
                    ></b-select>
                  </b-form-group>
                  <b-form-group label="Linear Combination"
                                description="In order, enter the coefficients for each feature with a plus sign between them, i.e. 1.3 + -2.0"
                                v-if="schema.metafeatures[key].aggregation === 'linear_combination'">
                    <b-form-input
                        v-model="schema.metafeatures[key].linear_combination"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group label="Normalisation" description="Do you want the values normalised?">
                    <b-select
                        v-model="schema.metafeatures[key].normalisation"
                        :options="valid_normalisations"
                    ></b-select>
                  </b-form-group>
                  <DatasetFeatureMultiSelect
                      v-model="schema.metafeatures[key].features"
                      :valid_features="valid_features"
                      :multiple="true"
                      show_features
                  ></DatasetFeatureMultiSelect>
                </b-collapse>
              </b-card>
            </b-collapse>

          </div>

          <div class="schema-editor-item">
            <h4 v-b-toggle.points>
              <span class="when-open">V</span><span class="when-closed">></span>
              Generated Points
            </h4>

            <b-collapse id="points" accordion="schema-editor-accordian">
              <p>
                This section allows you to modify how many points are generated, and the method used for generating
                them.
                Typically, values for the lower upper bound should be around 600 and 1200 respectively.
                The estimate is usually in the middle.
                If dynamic modelling is on, the number of points will be generated dynamically based on the data.
                If not, the estimate is used directly (although the final number of points may be slightly different).
              </p>
              <b-form-group label="Lower Bound">
                <label class="sr-only" for="inline-form-input-name">Lower</label>
                <b-form-input
                    id="inline-form-input-name"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    placeholder="600"
                    type="number"
                    v-model="schema.points.dynamic_lower"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Upper Bound">
                <label class="sr-only" for="inline-form-input-name">Upper</label>
                <b-form-input
                    id="inline-form-input-name"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    placeholder="1200"
                    type="number"
                    v-model="schema.points.dynamic_upper"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Estimate">
                <label class="sr-only" for="inline-form-input-name">Estimate</label>
                <b-form-input
                    id="inline-form-input-name"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    placeholder="1000"
                    type="number"
                    v-model="schema.points.estimate"
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox
                    size="lg"
                    buttons
                    v-model="schema.points.dynamic"
                >Use Dynamic modelling?
                </b-form-checkbox>
              </b-form-group>
            </b-collapse>
          </div>

          <div class="schema-editor-item">
            <h4 v-b-toggle.required>
              <span class="when-open">V</span><span class="when-closed">></span>
              Required Keys
            </h4>


            <b-collapse id="required" accordion="schema-editor-accordian">
              <p>The dashboard is only valid if these keys are present:</p>
              <DatasetFeatureMultiSelect
                  v-model="schema.required_keys"
                  :valid_features="valid_features"
                  :multiple="true"
                  show_features
              ></DatasetFeatureMultiSelect>
            </b-collapse>
          </div>
        </div>
      </b-collapse>
    </b-card>

    <b-card>
      <h3 v-b-toggle.pages-editor>
        <span class="when-open">V</span><span class="when-closed">></span>
        Pages Editor
      </h3>
      <b-collapse id="pages-editor">
        <div class="schema-editor-item">
          <b-form inline>
            <h5>Append New Page:&nbsp;</h5>
            <b-input v-model="newPageName"></b-input>
            <b-button v-on:click="addNewPage()" class="success">Add New Page</b-button>
          </b-form>
          <br>

          <b-card v-for="(page, key) in schema.pages" v-bind:key="key">
            <h5>
              Page: {{ key }}
              <b-button v-b-toggle="slug(key)" variant="outline-primary" size="sm">Show</b-button>
              <b-button
                  @click="deletePage(key)"
                  variant="outline-danger"
                  size="sm"
                  class="pull-right"
              >Delete
              </b-button
              >
            </h5>
            <b-collapse :id="slug(key)" accordion="pages">

              <PageEditor v-model="schema.pages[key]"
                          :valid_dataset_features="valid_features"></PageEditor>

            </b-collapse>
          </b-card>
          <b-button v-b-toggle.page-editor-advanced variant="outline-primary" size="sm">Show Advanced Tools</b-button>

          <b-collapse id="page-editor-advanced">
            <!-- button to clear all pages, but get confirmation first -->
            <b-button
                @click="clearAllPages()"
                variant="outline-danger"
                size="sm"
                class="pull-right" style="margin-top: 10px;"
            >Clear All Pages
            </b-button>
          </b-collapse>

        </div>
      </b-collapse>
    </b-card>
    <b-card>
      <h3 v-b-toggle.other-information>
        <span class="when-open">V</span><span class="when-closed">></span>
        Dashboard Metadata
      </h3>
      <b-collapse id="other-information">
        <p>This information cannot be directly edited in this view.</p>

        <div v-for="key in top_level_key_order" v-bind:key="key">
          <h4>{{ key }}</h4>
          <div class="schema-editor-item">
            {{ schema[key] }}
          </div>
        </div>


        {{ Object.keys(schema) }}
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import DatasetCategoryMultiSelect from "@/components/report_editing/DatasetMultiSelect.vue";
import Vue from "vue";
import DatasetFeatureMultiSelect from "@/components/report_editing/DatasetFeatureMultiSelect.vue";
import PageEditor from "@/components/report_editing/PageEditor.vue";
import DatasetService from "@/services/dataset.service";

export default {
  name: "SchemaEditor",
  components: {
    PageEditor, DatasetFeatureMultiSelect,
    DatasetCategoryMultiSelect
  },
  props: {
    schema: Object,
    datasets: Array,
  },
  data() {
    return {
      top_level_key_order: [
        'user',
        'poi_overlay',
      ],
      selected_dashboard_to_copy: null,
      dashboard_list: null,
      features: [],
      valid_metadata_operations: [
        'sum',
        'mean',
        'median',
        'min',
        'max',
        'std',
        'count',
        'n_unique',
        'negation',
        'linear_combination'
      ],
      valid_normalisations: [
        "none",
        "percentage_population",
        "population_rate",
        "minmax_scale_features"
      ],
      new_metafeature_name: "",
      feature_filter: "",

      newPageName: "",
      emptyPage: {
        "content": [{
          "col-size": 12,
          "components": [],
          "id": "newPage",
          "options": {"force-square": false, "title-placement": "external"},
          "title": "University"
        }],
        "navigation": []
      },
    };
  },
  computed: {
    valid_features() {
      // Add all the metafeatures to the features and return the list
      let features = this.features;
      for (let key in this.schema.metafeatures) {
        features.push(key);
      }
      return features;
    }
  },
  methods: {
    addNewPage() {
      let newPageData = JSON.parse(JSON.stringify(this.emptyPage));
      newPageData['id'] = this.slug(this.newPageName)
      Vue.set(this.schema.pages, this.newPageName, newPageData);
    },
    clearAllPages() {
      // Get confirmation from user first with a bootstrap modal
      this.$bvModal.msgBoxConfirm('Are you sure you want to clear all pages?')
          .then(value => {
            if (value) {
              // User clicked yes
              this.schema.pages = {};
            } else {
              // User clicked no
            }
          })
          .catch(err => {
            // An error occurred
          })
    },
    slug(name) {
      // slugify a name
      return name.toLowerCase().replace(/ /g, "-");
    },
    update_dashboard() {
      this.$emit("update_dashboard");
    },
    format_json() {
      this.$emit("format_json");
    },
    reset_schema_from_dashboard() {
      this.$emit("reset_schema_from_dashboard");
    },
    copy_from_dashboard() {
      this.$emit("copy_from_dashboard", this.selected_dashboard_to_copy);
    },
    deletePage(key) {
      Vue.delete(this.schema.pages, key);
    },
    deleteMetafeature(key) {
      Vue.delete(this.schema.metafeatures, key);
    },
    addMetafeature() {
      Vue.set(this.schema.metafeatures, this.new_metafeature_name, {
        aggregation: "sum",
        normalisation: "none",
        features: []
      });
      this.new_metafeature_name = "";
    },
    async loadAllFeatures() {
      DatasetService.getAllDatasetFeatures().then(
          (response) => {
            // Only the name from all features in response.data['datasets']
            this.features = response.data['datasets'].map(x => x.name);
            console.log('Dataset Features:');
            console.log(this.features);
            this.loading = false
          },
          (error) => {
            this.content =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
            this.loading = false
          }
      );
    },
  },
  async mounted() {
    // If points is a string, convert it to an object
    if (typeof this.schema.points === "string") {
      this.schema.points = {
        static: this.schema.points,
        dynamic_lower: 0,
        dynamic_upper: 0,
      }
    }
    await this.loadAllFeatures()
  }
}
</script>

<style scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>