<template>
  <div class="data-set-container">
    <Loading :is_loading="!categories" class="loading" message="Loading Categories..."/>
    <!--button to select all categories-->
    <b-button v-if="categories" @click="selectAll" variant="outline-primary" size="sm"
              class="select-all-button">
      Select All
    </b-button>
    <!--button to deselect all categories-->
    <b-button v-if="categories" @click="clearAll" variant="outline-primary" size="sm"
              class="select-all-button">
      Deselect All
    </b-button>
    <div class="data-set-container" v-if="categories">
      <div v-for="(category, idx) of categories" v-bind:key="idx" @click="toggleCategory(category)"
           class="rebutton-icon" :class="{'selected': isCategorySelected(category)}">
        <div class="icon" :class="{'selected': isCategorySelected(category)}">
          <font-awesome-icon icon="database" size="1x"/>
        </div>
        {{ category }}
      </div>
    </div>
    {{ value }}
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";

export default {
  name: "DatasetCategoryMultiSelect",
  components: {Loading},
  props: {
    value: Array,
    categories: Array,
    multiple: Boolean,
    select_size: Number,
  },
  data() {
    return {
      loading: true,
      selectedCategories: []
    }
  },
  computed: {
    validated() {
      return this.selectedCategories.length > 0;
    }
  },
  methods: {
    toggleCategory(cat) {
      if (this.isCategorySelected(cat)) {
        this.selectedCategories = this.selectedCategories.filter(x => x !== cat)
      } else {
        this.selectedCategories.push(cat)
      }
      this.$emit('categories_changed', this.selectedCategories)
    },
    clearAll() {
      this.selectedCategories = []
      this.$emit('categories_changed', this.selectedCategories)
    },
    selectAll() {
      this.selectedCategories = this.categories
      this.$emit('categories_changed', this.selectedCategories)
    },
    isCategorySelected(cat) {
      return this.selectedCategories.includes(cat)
    },
    getIcon(iconName) {
      if (iconName) {
        return 'iconName'
      } else {
        return 'database'
      }
    }
  },
  mounted() {
    this.selectedCategories = this.value
  }
}
</script>

<style scoped>

.data-set-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>